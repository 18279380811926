import { Injectable } from '@angular/core';
import { environment } from '@berater/environments/environment';
import { Environment } from '@berater/environments/model/environment.model';

@Injectable({
  providedIn: 'root',
})
export class BeraterEnvironmentService {
  private environment: Environment;

  constructor() {
    this.environment = environment;
  }

  get getApiUrl(): string {
    return this.environment.apiUrl;
  }

  getBaseUrl(): string {
    return this.environment.baseUrl;
  }

  get getKeycloakUrl(): string {
    return this.environment.keycloakUrl;
  }

  get getStaticContentUrl() {
    return this.environment.staticContentUrl;
  }

  get getImpersonationUrl(): string {
    return this.environment.impersonationUrl;
  }

  get getImpersonationTarget(): string {
    return this.environment.impersonationTarget;
  }

  get getImpersonationTargetUrl(): string {
    return this.environment.impersonationTargetUrl;
  }

  get getImpersonationCollaborationUrl(): string {
    return this.environment.impersonationCollaborationUrl;
  }

  get getImpersonationLogWienService(): string {
    return this.environment.impersonationLogWienService;
  }

  get getImpersonationLogWienPartner(): string {
    return this.environment.impersonationLogWienPartner;
  }

  get getLoggingAPIOBaseURL(): string {
    return '';
  }

  get isProduction(): boolean {
    return this.environment.production;
  }

  get isRegistrationEnabled(): boolean {
    return this.environment.isRegistrationEnabled;
  }
}
