import { Environment } from './model/environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://apim-psp-backend-dev.azure-api.net/api',
  keycloakUrl: 'https://ca-psp-keycloak-dev.mangoriver-998e02b9.westeurope.azurecontainerapps.io',
  keycloakRealm: 'wienenergie',
  keycloakClient: 'client-meinwienenergie-public',
  baseUrl: 'https://dev-berater-unternehmen.wienenergie.at',
  staticContentUrl: 'https://test-static.wienenergie.at/projects/premiumserviceportal/config',
  impersonationUrl: 'https://ca-psp-keycloak-dev.mangoriver-998e02b9.westeurope.azurecontainerapps.io/admin/master/console',
  impersonationTarget: 'KGUcollaborate',
  impersonationTargetUrl: 'https://test-unternehmen.wienenergie.at/home',
  impersonationCollaborationUrl:
    'https://test.log.wien/rest/logwien/admin/users/{userId}/partners/{partnerName}/services/{serviceName}/impersonation',
  impersonationLogWienService: 'we-ikp',
  impersonationLogWienPartner: 'wien-energie',
  isRegistrationEnabled: true,
};
